<template>
  <div>
    <NavigationBar name="邀请成员"> </NavigationBar>

    <div class="content">
      <div class="img-content">
        <img class="bg-img" :src="bgImg" width="100%">
      </div>
      <p id="tip">邀请您单位的成员填写信息，并由单位管理员审核</p>
      <div class="btn-content">
        <md-button type="primary" @click="$router.push('/inviteLink')">邀请成员</md-button>
        <div style="margin-top: 30px">
          <md-button type="primary" plain @click="$router.push('/inviteAudit')">审核状态</md-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
    import {Button} from 'mand-mobile'

    export default {
        name: "InviteHome",
        components: {
            [Button.name]: Button
        },
        data() {
            return {
                bgImg: require('@/assets/inviteImages/background.png'),
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
.img-content {
    padding: 0 5%;
}
.bg-img {
    margin-top: 60px;
}
.btn-content {
    margin-top: 80px;
    padding: 0 7%;
}
#tip{
  font-size: 16px;
  text-align: center;
}
</style>
